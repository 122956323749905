import React from 'react';
import DecorativeBackground from '../../UI/DecorativeBackground';
import { css } from '@emotion/react';
import { EMOTION_BREAKPOINTS } from '../../../constants/breakpoints';

const Masthead = () => (
  <DecorativeBackground color="teal">
    <div
      css={css`
        max-width: 950px;
        text-align: center;
        margin: 0 auto;

        padding: 35px 23px;
        font-size: 12px;
        ${EMOTION_BREAKPOINTS.sm} {
          font-size: 16px;
          padding: 80px 0;
        }
        h3 {
          font-size: 2.25em;
          font-weight: 600;
          margin-bottom: 0;
        }
      `}
    >
      <h3>Find Your Answer</h3>
    </div>
  </DecorativeBackground>
);

export default Masthead;
