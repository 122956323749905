import React from 'react';
import PropTypes from 'prop-types';
import bgSVG from '../../../images/bgGraphic.svg';
import styled from '@emotion/styled';
import theme from '../../../theme';

const Container = styled.div`
  background-color: ${({ theme, color }) => {
    // does theme have color ?
    if (theme.colors[color]) return theme.colors[color];
    // default to dark grey
    return theme.colors.darkgrey;
  }};
  padding: ${({ theme }) => theme.spacing['8x']}
    ${({ theme }) => theme.spacing['3x']};
  color: #fff;
  text-align: center;
  position: relative;
  > * {
    opacity: initial;
    z-index: 2;
    position: relative;
  }
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  background-size: 1500px;
  background-attachment: fixed;
  background-image: url(${bgSVG});
  opacity: 0.34 !important;
  top: 0;
  left: 0;
  z-index: 1 !important;
  position: absolute !important;
`;

/**
 * a wrapper that provide the colored background against a offwhite svg background imgae
 * usage would be <DecorativeBackground><div> your content here </div></DecorativeBackground>
 * @param {Object} props
 * @param {String} props.color
 */
export const DecorativeBackground = ({ color, children, ...rest }) => (
  <Container color={color} {...rest}>
    <Background />
    {children}
  </Container>
);

DecorativeBackground.propTypes = {
  color: PropTypes.oneOf(Object.keys(theme.colors)),
  children: PropTypes.node,
};

export default DecorativeBackground;
