import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Masthead from '../components/FAQ/Masthead';
import FaqType from '../components/FAQ/Type';
import { Section, SectionTitle } from '../components/UI';
import { Flex, Box } from '@rebass/grid/emotion';
import { css } from '@emotion/react';
const FaqPage = ({ data, location, ...rest }) => (
  <Layout>
    <Seo title="FAQ" />
    <Masthead />
    <Section>
      <SectionTitle>Browse By Topic</SectionTitle>
      <Flex
        flexWrap="wrap"
        css={css`
          max-width: 1184px;
          margin: 0 auto;
        `}
        justifyContent="center"
      >
        {data.allFaqTypesYaml.edges.map((faq) => (
          <Box
            key={faq.node.id}
            width={[1, 1 / 4]}
            p={17.5}
            css={css`
              max-width: 281px;
              min-width: 281px;
            `}
          >
            <FaqType
              title={faq.node.title}
              slug={`${location.pathname}/${faq.node.slug}`}
              fixedImage={faq.node.image.childImageSharp.fixed}
            />
          </Box>
        ))}
      </Flex>
    </Section>
  </Layout>
);

export const FAQ = graphql`
  query FAQ {
    allFaqTypesYaml {
      edges {
        node {
          id
          title
          slug
          image {
            id
            ...graphicFaqTypeImage
          }
        }
      }
    }
  }
`;
export default FaqPage;
