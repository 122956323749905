import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Card from '../../UI/Card';
import Link from '../../UI/Link';
import { EMOTION_BREAKPOINTS } from '../../../constants/breakpoints';
import { boxShadow, borderRadiusSecondary } from '../../../styleTokens';

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 235px;
  img {
    margin-bottom: 0;
  }
`;

const ContentContainer = styled.div`
  flex: 1 0 auto;
  background-color: white;
  text-align: center;
  padding: 29px 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  h3 {
    color: ${({ theme }) => theme.colors.darkgrey};
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
  }
  a {
    color: ${({ theme }) => theme.colors.blue};
    text-decoration: none;
  }
`;

const FaqType = ({ title, slug, fixedImage }) => {
  return (
    <Card
      fixedImage={fixedImage}
      color="lightgrey"
      css={css`
        width: 261px;
        height: 340px;
        ${borderRadiusSecondary};
        ${boxShadow};
        display: flex;
        flex-flow: column nowrap;
        background-color: #f8f8f8;
        margin: 0;
        ${EMOTION_BREAKPOINTS.sm} {
          margin: 0;
        }
      `}
    >
      <ImageContainer>
        <GatsbyImage image={fixedImage} alt={title} />
      </ImageContainer>
      <ContentContainer>
        <h3>{title}</h3>
        <Link to={slug}>Learn More</Link>
      </ContentContainer>
    </Card>
  );
};

FaqType.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  fixedImage: PropTypes.object.isRequired,
};

export const graphicFaqTypeImage = graphql`
  fragment graphicFaqTypeImage on File {
    childImageSharp {
      gatsbyImageData(width: 202)
    }
  }
`;

export default FaqType;
